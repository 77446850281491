import store from "@/store";

export const location = (): void => {
  if (navigator && navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      (succ) => {
        const lat = succ.coords.latitude;
        const lng = succ.coords.longitude;
        console.info(`geolocation success: ${lat},${lng}`);
        store.commit("updateCoords", { lat: lat, lng: lng });
      },
      (fail) => {
        console.warn(`geolocation failure: ${fail}`);
      }
    );
  }
};
