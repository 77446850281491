<template>
  <div class="month">
    <Card class="p-shadow-6">
      <template #title>
        <div class="p-text-uppercase p-text-center">
          {{ monthname }}, {{ dd }}
        </div>
      </template>
      <template #content>
        <div class="p-d-flex p-jc-center p-flex-wrap monospace">
          <div class="p-mx-2">day light: {{ dayLenght }}</div>
        </div>
      </template>
    </Card>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { DateTime, Info } from "luxon";
import { dataForDay } from "@/domain/SunRiseSunSet";
import { useStore } from "vuex";
import { key } from "@/store";

export default defineComponent({
  name: "Month",
  props: {
    month: { type: Number, required: true },
  },
  setup(props) {
    const store = useStore(key);
    const monthname = ref("");
    const dayLenght = ref("..:..:..");

    const dt = DateTime.now();
    const yy = ref(dt.year);
    const dd = ref(dt.day);

    const allMonths = () => {
      const months = Info.months("short", {});
      console.info(`month ${months}`);
      monthname.value = months[props.month];
    };
    onMounted(() => {
      allMonths();
      store.subscribe((mutation, state) => {
        console.info(
          `mutation type: ${mutation.type} state: ${JSON.stringify(state)}`
        );
        if (mutation.type === "updateCoords") {
          const mm = props.month + 1;
          const dayLenghtPromise = dataForDay(dd.value, mm, yy.value);
          dayLenghtPromise.then((dl) => {
            dayLenght.value = dl.min;
          });
        }
      });
    });
    return {
      monthname,
      dayLenght,
      yy,
      dd,
    };
  },
});
</script>

<style scoped>
.monospace {
  font-family: monospace;
}
</style>
