
import { defineComponent, ref } from "vue";
import Month from "@/components/Month.vue";

export default defineComponent({
  name: "Months",
  components: {
    Month,
  },
  setup() {
    const allMonths = ref(Array.from(Array(12).keys()));

    return {
      allMonths,
    };
  },
});
