<template>
  <div class="home">
    <div class="p-grid">
      <div class="p-col">
        <div><Today /></div>
      </div>
      <div class="p-col">
        <div><Location /></div>
      </div>
    </div>
    <Months />
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted } from "vue";
import Months from "@/components/Months.vue";
import Today from "@/components/Today.vue";
import Location from "@/components/Location.vue";
import { location } from "@/domain/GeoLocation";

export default defineComponent({
  name: "Home",
  components: {
    Months,
    Today,
    Location,
  },
  setup() {
    onMounted(() => {
      location();
    });
  },
});
</script>
