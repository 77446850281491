<template>
  <div class="months">
    <div class="p-grid">
      <Month
        v-for="month in allMonths"
        v-bind:key="month"
        :month="month"
        class="p-px-2 p-py-2 p-col-12 p-md-6 p-lg-4"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import Month from "@/components/Month.vue";

export default defineComponent({
  name: "Months",
  components: {
    Month,
  },
  setup() {
    const allMonths = ref(Array.from(Array(12).keys()));

    return {
      allMonths,
    };
  },
});
</script>
