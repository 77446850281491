
import { computed, defineComponent } from "vue";
import { useStore } from "vuex";
import { key } from "@/store";

export default defineComponent({
  name: "Location",

  setup() {
    const store = useStore(key);

    return {
      lat: computed(() => store.state.coords.lat),
      lng: computed(() => store.state.coords.lng),
    };
  },
});
