<template>
  <main>
    <div class="p-d-flex p-shadow-6 p-my-1 p-jc-center header-bg">
      <div class="test">
        <div class="p-text-center p-text-bold p-text-uppercase">
          <h3>day light calculator</h3>
        </div>
      </div>
    </div>
    <router-view />
  </main>
  <footer class="p-d-flex p-jc-end p-py-1 p-px-2 p-shadow-1">
    data from <a href="https://sunrise-sunset.org/api" class="p-pl-1">Sunrise Sunset</a>
  </footer>
</template>

<script lang="ts">
import "primeflex/primeflex.css";
import { defineComponent } from "vue";
export default defineComponent({
  name: "App",
});
</script>

<style>
main {
  font-family: "Roboto", sans-serif;
}

.header-bg {
  background-color: var(--blue-200);
}
</style>
