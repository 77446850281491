import store from "@/store";
import { DateTime } from "luxon";

export class DayLenght {
  constructor(readonly max: string, readonly min: string) {}
}

export const dataForDay = (
  dd: number,
  mm: number,
  yy: number
): Promise<DayLenght> => {
  const minMonthDate = DateTime.fromObject({ year: yy, month: mm });
  const maxMonthDate = minMonthDate.endOf("month");
  console.info(
    `month min day: ${minMonthDate.toISODate()}, max day ${maxMonthDate.toISODate()}`
  );
  if (minMonthDate.day <= dd && maxMonthDate.day >= dd) {
    return new Promise((resolve, reject) => {
      const lat = store.state.coords.lat;
      const lng = store.state.coords.lng;
      const ddParam = `${dd}`;
      const mmParam = `${mm}`.padStart(2, "0");
      const yyParam = `${yy}`;
      fetch(
        `https://api.sunrise-sunset.org/json?lat=${lat}&lng=${lng}&date=${yyParam}-${mmParam}-${ddParam}`
      )
        .then((response) => response.json())
        .then((data: { results: { day_length: string } }) => {
          console.info(
            `yy ${yyParam} / mm ${mmParam} / dd ${ddParam}: ${data.results.day_length}`
          );
          resolve(
            new DayLenght(data.results.day_length, data.results.day_length)
          );
        })
        .catch((error) => reject(error));
    });
  }
  return new Promise((resolve) => {
    resolve(new DayLenght("no data", "no data"));
  });
};
