import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store, { key } from "./store";
import PrimeVue from "primevue/config";
import Card from "primevue/card";
import Panel from "primevue/panel";
import Badge from "primevue/badge";

createApp(App)
  .use(store, key)
  .use(router)
  .use(PrimeVue)
  .component("Card", Card)
  .component("Panel", Panel)
  .component("Badge", Badge)
  .mount("#app");
