
import { computed, defineComponent, onMounted, ref } from "vue";
import { DateTime } from "luxon";
import { useStore } from "vuex";
import { key } from "@/store";

export default defineComponent({
  name: "Today",

  setup() {
    const store = useStore(key);
    const today = ref("");

    const calculateToday = () => {
      const dt = DateTime.now();
      const date = dt.toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY);
      console.info(`date ${date}`);
      today.value = date;
    };

    onMounted(() => {
      calculateToday();
    });

    return {
      today,
      lat: computed(() => store.state.coords.lat),
      lng: computed(() => store.state.coords.lng),
    };
  },
});
