import { InjectionKey } from "vue";
import { createStore, Store } from "vuex";

export interface State {
  coords: { lat: number; lng: number };
}

export const key: InjectionKey<Store<State>> = Symbol();

export default createStore<State>({
  state: { coords: { lat: 0.0, lng: 0.0 } },
  mutations: {
    updateCoords(state, payload: { lat: number; lng: number }) {
      console.info(`mutation for coords: ${JSON.stringify(payload)}`);
      state.coords.lat = payload.lat;
      state.coords.lng = payload.lng;
    },
  },
  actions: {},
  modules: {},
});
