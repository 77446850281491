
import { defineComponent, onMounted } from "vue";
import Months from "@/components/Months.vue";
import Today from "@/components/Today.vue";
import Location from "@/components/Location.vue";
import { location } from "@/domain/GeoLocation";

export default defineComponent({
  name: "Home",
  components: {
    Months,
    Today,
    Location,
  },
  setup() {
    onMounted(() => {
      location();
    });
  },
});
