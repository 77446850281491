<template>
  <div class="location p-py-2">
    <Panel class="p-shadow-6" header="current location">
      <div class="p-py-1 p-d-inline-flex" v-if="(lat > 0) & (lng > 0)">
        <div class="p-mx-2">{{ lat }}</div>
        <div class="p-mx-2">{{ lng }}</div>
      </div>
      <div class="p-py-1" v-else>your position is unknown</div>
    </Panel>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import { useStore } from "vuex";
import { key } from "@/store";

export default defineComponent({
  name: "Location",

  setup() {
    const store = useStore(key);

    return {
      lat: computed(() => store.state.coords.lat),
      lng: computed(() => store.state.coords.lng),
    };
  },
});
</script>
